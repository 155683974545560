import React from "react"
import { Container, Button, Col, Media, Image, Row } from "react-bootstrap"
import TopMenu from "../components/Layout/TopMenu"
import { LoremIpsum } from "react-lorem-ipsum"
import Footer from "../components/Layout/Footer"

const SecondPage = () => (
  <Container fluid className="home-container p-0">
        <TopMenu></TopMenu>
        <Container fluid className="home-slider">
          <Container fluid="xl" className="home-slider-container d-flex flex-column ">
          <Row>
              
              <Col lg={{ order: "last" }} md={{ order: "first" }} className="pl-xl-4">
              <h2>Rewards</h2>
                  <LoremIpsum avgWordsPerSentence={6} avgSentencesPerParagraph={6} />

                  <Media>
                    
                    <Media.Body>
                      <LoremIpsum avgWordsPerSentence={4} avgSentencesPerParagraph={4} />
                    </Media.Body>
                  </Media>
                  <Media>
                    <Media.Body>
                      <LoremIpsum avgWordsPerSentence={4} avgSentencesPerParagraph={4} />
                    </Media.Body>
                  </Media>
                  <Media>
                    
                    <Media.Body>
                      <LoremIpsum avgWordsPerSentence={4} avgSentencesPerParagraph={4} />
                    </Media.Body>
                  </Media>
                
              </Col>
              <Col lg={{ order: "last" }} md={{ order: "first" }} className="pl-xl-4">
                
              </Col>
        </Row>
          </Container>
        </Container>

        <Container fluid className="firstcont py-xl-6">
        <Container fluid="xl" className="state-page-container d-flex flex-column ">
        <Row>
              
              <Col lg={{ order: "last" }} md={{ order: "first" }} className="pl-xl-4">
              <h2>Rewards</h2>
                  <LoremIpsum avgWordsPerSentence={6} avgSentencesPerParagraph={6} />

                  <Media>
                    
                    <Media.Body>
                      <LoremIpsum avgWordsPerSentence={4} avgSentencesPerParagraph={4} />
                    </Media.Body>
                  </Media>
                  <Media>
                    <Media.Body>
                      <LoremIpsum avgWordsPerSentence={4} avgSentencesPerParagraph={4} />
                    </Media.Body>
                  </Media>
                  <Media>
                    
                    <Media.Body>
                      <LoremIpsum avgWordsPerSentence={4} avgSentencesPerParagraph={4} />
                    </Media.Body>
                  </Media>
                
              </Col>
              <Col lg={{ order: "last" }} md={{ order: "first" }} className="pl-xl-4">
              <h3 className="phoneimg mt-5 w-75"></h3>
              </Col>
        </Row>
        
          </Container>
        </Container>
        <Container fluid className="home-slider py-xl-6">
          <Container fluid="xl" className="home-slider-container d-flex flex-column ">
          <Row>
              
              <Col lg={{ order: "last" }} md={{ order: "first" }} className="pl-xl-4">
                
                
              </Col>
              <Col lg={{ order: "last" }} md={{ order: "first" }} className="pl-xl-4">
              <h2>Rewards</h2>
                  <LoremIpsum avgWordsPerSentence={6} avgSentencesPerParagraph={6} />

                  <Media> 
                    <Media.Body>
                      <LoremIpsum avgWordsPerSentence={4} avgSentencesPerParagraph={4} />
                    </Media.Body>
                  </Media>
                  <Media>
                    <Media.Body>
                      <LoremIpsum avgWordsPerSentence={4} avgSentencesPerParagraph={4} />
                    </Media.Body>
                  </Media>
                  <Media>
                    <Media.Body>
                      <LoremIpsum avgWordsPerSentence={4} avgSentencesPerParagraph={4} />
                    </Media.Body>
                  </Media>
                  
              </Col>
        </Row>
          </Container>
        </Container>

        <Container fluid className="firstcont py-xl-6">
        <Container fluid="xl" className="state-page-container d-flex flex-column ">
        <Row>
              
              <Col lg={{ order: "last" }} md={{ order: "first" }} className="pl-xl-4">
              <h2>Rewards</h2>
                  <LoremIpsum avgWordsPerSentence={6} avgSentencesPerParagraph={6} />

                  <Media>
                    
                    <Media.Body>
                      <LoremIpsum avgWordsPerSentence={4} avgSentencesPerParagraph={4} />
                    </Media.Body>
                  </Media>
                  <Media>
                    <Media.Body>
                      <LoremIpsum avgWordsPerSentence={4} avgSentencesPerParagraph={4} />
                    </Media.Body>
                  </Media>
                  <Media>
                    
                    <Media.Body>
                      <LoremIpsum avgWordsPerSentence={4} avgSentencesPerParagraph={4} />
                    </Media.Body>
                  </Media>
                  
                
              </Col>
              <Col lg={{ order: "last" }} md={{ order: "first" }} className="pl-xl-4">
                
              </Col>
        </Row>
        
          </Container>
        </Container>
        <Container fluid className="home-slider py-xl-6">
          <Container fluid="xl" className="home-slider-container d-flex flex-column ">
          <Row>
              
              <Col lg={{ order: "last" }} md={{ order: "first" }} className="pl-xl-4">
              <h2>Rewards</h2>
                  <LoremIpsum avgWordsPerSentence={6} avgSentencesPerParagraph={6} />

                  <Media>
                    
                    <Media.Body>
                      <LoremIpsum avgWordsPerSentence={4} avgSentencesPerParagraph={4} />
                    </Media.Body>
                  </Media>
                  <Media>
                    <Media.Body>
                      <LoremIpsum avgWordsPerSentence={4} avgSentencesPerParagraph={4} />
                    </Media.Body>
                  </Media>
                  <Media>
                    
                    <Media.Body>
                      <LoremIpsum avgWordsPerSentence={4} avgSentencesPerParagraph={4} />
                    </Media.Body>
                  </Media>
                  <Media> 
                    <Media.Body>
                      <LoremIpsum avgWordsPerSentence={4} avgSentencesPerParagraph={4} />
                    </Media.Body>
                  </Media>
                  <Media>
                    <Media.Body>
                      <LoremIpsum avgWordsPerSentence={4} avgSentencesPerParagraph={4} />
                    </Media.Body>
                  </Media>
                  <Media>
                    <Media.Body>
                      <LoremIpsum avgWordsPerSentence={4} avgSentencesPerParagraph={4} />
                    </Media.Body>
                  </Media>
                  <Media> 
                    <Media.Body>
                      <LoremIpsum avgWordsPerSentence={4} avgSentencesPerParagraph={4} />
                    </Media.Body>
                  </Media>
                  <Media>
                    <Media.Body>
                      <LoremIpsum avgWordsPerSentence={4} avgSentencesPerParagraph={4} />
                    </Media.Body>
                  </Media>
                  <Media>
                    <Media.Body>
                      <LoremIpsum avgWordsPerSentence={4} avgSentencesPerParagraph={4} />
                    </Media.Body>
                  </Media>
                
              </Col>
              <Col lg={{ order: "last" }} md={{ order: "first" }} className="pl-xl-4">
                
              </Col>
        </Row>
          </Container>
        </Container>

        <Container fluid className="firstcont py-xl-6">
        <Container fluid="xl" className="state-page-container d-flex flex-column ">
        <Row>
              
              <Col lg={{ order: "last" }} md={{ order: "first" }} className="pl-xl-4">
                
                
              </Col>
              <Col lg={{ order: "last" }} md={{ order: "first" }} className="pl-xl-4">
              <h2>Rewards</h2>
                  <LoremIpsum avgWordsPerSentence={6} avgSentencesPerParagraph={6} />

                  <Media>
                    
                    <Media.Body>
                      <LoremIpsum avgWordsPerSentence={4} avgSentencesPerParagraph={4} />
                    </Media.Body>
                  </Media>
                  <Media>
                    <Media.Body>
                      <LoremIpsum avgWordsPerSentence={4} avgSentencesPerParagraph={4} />
                    </Media.Body>
                  </Media>
                  <Media>
                    
                    <Media.Body>
                      <LoremIpsum avgWordsPerSentence={4} avgSentencesPerParagraph={4} />
                    </Media.Body>
                  </Media>
                  <Media> 
                    <Media.Body>
                      <LoremIpsum avgWordsPerSentence={4} avgSentencesPerParagraph={4} />
                    </Media.Body>
                  </Media>
                  <Media>
                    <Media.Body>
                      <LoremIpsum avgWordsPerSentence={4} avgSentencesPerParagraph={4} />
                    </Media.Body>
                  </Media>
                  <Media>
                    <Media.Body>
                      <LoremIpsum avgWordsPerSentence={4} avgSentencesPerParagraph={4} />
                    </Media.Body>
                  </Media>
                  <Media> 
                    <Media.Body>
                      <LoremIpsum avgWordsPerSentence={4} avgSentencesPerParagraph={4} />
                    </Media.Body>
                  </Media>
                  <Media>
                    <Media.Body>
                      <LoremIpsum avgWordsPerSentence={4} avgSentencesPerParagraph={4} />
                    </Media.Body>
                  </Media>
                  <Media>
                    <Media.Body>
                      <LoremIpsum avgWordsPerSentence={4} avgSentencesPerParagraph={4} />
                    </Media.Body>
                  </Media>
              </Col>
        </Row>
        
          </Container>
        </Container>
        <Container fluid className="home-slider py-xl-6">
          <Container fluid="xl" className="home-slider-container d-flex flex-column ">
          <Row>
              
              <Col lg={{ order: "last" }} md={{ order: "first" }} className="pl-xl-4">
              <h2>Rewards</h2>
                  <LoremIpsum avgWordsPerSentence={6} avgSentencesPerParagraph={6} />

                  <Media>
                    
                    <Media.Body>
                      <LoremIpsum avgWordsPerSentence={4} avgSentencesPerParagraph={4} />
                    </Media.Body>
                  </Media>
                  <Media>
                    <Media.Body>
                      <LoremIpsum avgWordsPerSentence={4} avgSentencesPerParagraph={4} />
                    </Media.Body>
                  </Media>
                  <Media>
                    
                    <Media.Body>
                      <LoremIpsum avgWordsPerSentence={4} avgSentencesPerParagraph={4} />
                    </Media.Body>
                  </Media>
                  <Media> 
                    <Media.Body>
                      <LoremIpsum avgWordsPerSentence={4} avgSentencesPerParagraph={4} />
                    </Media.Body>
                  </Media>
                  <Media>
                    <Media.Body>
                      <LoremIpsum avgWordsPerSentence={4} avgSentencesPerParagraph={4} />
                    </Media.Body>
                  </Media>
                  <Media>
                    <Media.Body>
                      <LoremIpsum avgWordsPerSentence={4} avgSentencesPerParagraph={4} />
                    </Media.Body>
                  </Media>
                  <Media> 
                    <Media.Body>
                      <LoremIpsum avgWordsPerSentence={4} avgSentencesPerParagraph={4} />
                    </Media.Body>
                  </Media>
                  <Media>
                    <Media.Body>
                      <LoremIpsum avgWordsPerSentence={4} avgSentencesPerParagraph={4} />
                    </Media.Body>
                  </Media>
                  <Media>
                    <Media.Body>
                      <LoremIpsum avgWordsPerSentence={4} avgSentencesPerParagraph={4} />
                    </Media.Body>
                  </Media>
                
              </Col>
              <Col lg={{ order: "last" }} md={{ order: "first" }} className="pl-xl-4">
                
              </Col>
        </Row>
          </Container>
        </Container>

        <Footer></Footer>
      </Container>
)

export default SecondPage
